<script>
import range from 'lodash/range'
import { getAuthHeader } from '@/api/keycloakAuth'
import * as Sentry from '@sentry/vue'

function getDocument(url) {
  return import('pdfjs-dist/webpack').then((pdfjs) =>
    pdfjs.getDocument({
      url,
      httpHeaders: {
        ...getAuthHeader(),
      },
    })
  )
}

function getPages(pdf, first, last) {
  const allPages = range(first, last + 1).map((number) => pdf.getPage(number))
  return Promise.all(allPages)
}

const BUFFER_LENGTH = 10

function getDefaults() {
  return {
    pages: [],
    cursor: 0,
  }
}

export default {
  name: 'PdfData',
  props: {
    currentPdf: {
      type: String,
      required: true,
    },
  },

  data() {
    return Object.assign(getDefaults(), {
      pdf: undefined,
    })
  },

  watch: {
    currentPdf: {
      handler(url) {
        getDocument(url)
          .then((pdf) => (this.pdf = pdf))
          .catch((response) => {
            this.$emit('document-errored', {
              text: 'Failed to retrieve PDF',
              response,
            })
          })
      },
      immediate: true,
    },

    pdf(pdf, oldPdf) {
      if (!pdf) {
        return
      }
      if (oldPdf) {
        Object.assign(this, getDefaults())
      }

      this.$emit('page-count', this.pageCount)
      this.fetchPages()
    },
  },

  computed: {
    pageCount() {
      return this.pdf ? this.pdf.numPages : 0
    },
  },

  methods: {
    fetchPages(currentPage = 0) {
      if (!this.pdf) return
      if (this.pageCount > 0 && this.pages.length === this.pageCount) return

      const startIndex = this.pages.length
      if (this.cursor > startIndex) return

      const startPage = startIndex + 1
      const endPage = Math.min(
        Math.max(currentPage, startIndex + BUFFER_LENGTH),
        this.pageCount
      )
      this.cursor = endPage

      getPages(this.pdf, startPage, endPage)
        .then((pages) => {
          const deleteCount = 0
          this.pages.splice(startIndex, deleteCount, ...pages)
          return this.pages
        })
        .catch((response) => {
          this.$emit('document-errored', {
            text: 'Failed to retrieve pages',
            response,
          })
        })
    },

    onPageRendered({ text, page }) {},

    onPageErrored({ text, response, page }) {
      Sentry.captureException({ text, response, page })
    },
  },

  created() {
    this.$on('page-rendered', this.onPageRendered)
    this.$on('page-errored', this.onPageErrored)
    this.$on('pages-fetch', this.fetchPages)
  },
}
</script>

<template>
  <div>
    <slot v-bind="{ pages }" />
  </div>
</template>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"bottom",rawName:"v-bottom.immediate",value:(_vm.fetchPages),expression:"fetchPages",modifiers:{"immediate":true}},{name:"scroll",rawName:"v-scroll.immediate",value:(_vm.updateScrollBounds),expression:"updateScrollBounds",modifiers:{"immediate":true}}],staticClass:"scrolling-document"},_vm._l((_vm.pages),function(page){return _c('ScrollingPage',_vm._b({key:page.pageNumber,on:{"page-jump":_vm.onPageJump},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isElementVisible = ref.isElementVisible;
var isPageFocused = ref.isPageFocused;
var isElementFocused = ref.isElementFocused;
return _c('div',{staticClass:"scrolling-page"},[_vm._t("default",null,null,{
          page: page,
          isElementVisible: isElementVisible,
          isPageFocused: isPageFocused,
          isElementFocused: isElementFocused,
        })],2)}}],null,true)},'ScrollingPage',{
      page: page,
      clientHeight: _vm.clientHeight,
      scrollTop: _vm.scrollTop,
      focusedPage: _vm.focusedPage,
      enablePageJump: _vm.enablePageJump,
    },false))}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
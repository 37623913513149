var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollingDocument',_vm._b({directives:[{name:"resize",rawName:"v-resize",value:(_vm.fitWidth),expression:"fitWidth"}],staticClass:"pdf-document",attrs:{"enable-page-jump":true},on:{"page-jump":_vm.onPageJump,"pages-fetch":_vm.onPagesFetch,"pages-reset":_vm.fitWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var page = ref.page;
var isElementVisible = ref.isElementVisible;
var isPageFocused = ref.isPageFocused;
var isElementFocused = ref.isElementFocused;
return _c('PDFPage',_vm._b({attrs:{"is-snip-activated":_vm.isSnipActivated},on:{"page-rendered":_vm.onPageRendered,"page-errored":_vm.onPageErrored,"page-focus":_vm.onPageFocused,"snipAreaSelected":function (val) { return _vm.$emit('snipAreaSelected', val); }}},'PDFPage',{
      scale: _vm.scale,
      optimalScale: _vm.optimalScale,
      fields: _vm.fields,
      page: page,
      isElementVisible: isElementVisible,
      isPageFocused: isPageFocused,
      isElementFocused: isElementFocused,
      pageSizes: _vm.pageSizes,
    },false))}}])},'ScrollingDocument',{ pages: _vm.pages, pageCount: _vm.pageCount, currentPage: _vm.currentPage },false))}
var staticRenderFns = []

export { render, staticRenderFns }
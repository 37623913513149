<template>
  <v-card>
    <v-btn
      icon
      @click.prevent.stop="zoomIn"
      class="icon"
      :disabled="isDisabled"
    >
      <v-icon>zoom_in</v-icon>
    </v-btn>
    <v-btn
      icon
      @click.prevent.stop="zoomOut"
      class="icon"
      :disabled="isDisabled"
    >
      <v-icon>zoom_out</v-icon>
    </v-btn>
    <v-btn
      icon
      @click.prevent.stop="fitWidth"
      class="icon"
      :disabled="isDisabled"
    >
      <v-icon>unfold_more</v-icon>
    </v-btn>
    <v-btn
      icon
      @click.prevent.stop="fitAuto"
      class="icon"
      :disabled="isDisabled"
    >
      <v-icon>unfold_less</v-icon>
    </v-btn>
    <v-btn
      :disabled="isDisabled"
      @click.prevent.stop="$emit('download')"
      class="icon"
      icon
    >
      <v-icon>cloud_download</v-icon>
    </v-btn>
    <v-btn
      :color="snipButtonColor"
      :dark="isSnipActivated"
      :disabled="isDisabled"
      @click.prevent.stop="$emit('snipModeChange')"
      icon
    >
      <v-icon>tab_unselected</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'PdfZoom',
  props: {
    scale: {
      type: Number,
    },
    increment: {
      type: Number,
      default: 0.25,
    },
    isSnipActivated: Boolean,
  },

  computed: {
    isDisabled() {
      return !this.scale
    },
    snipButtonColor() {
      return this.isSnipActivated ? 'black' : ''
    },
  },

  methods: {
    zoomIn() {
      this.updateScale(this.scale + this.increment)
    },

    zoomOut() {
      if (this.scale <= this.increment) return
      this.updateScale(this.scale - this.increment)
    },

    updateScale(scale) {
      this.$emit('change', {
        scale,
      })
    },

    fitWidth() {
      this.$emit('fit', 'width')
    },

    fitAuto() {
      this.$emit('fit', 'auto')
    },
  },
}
</script>

<style></style>

<script>
import debug from 'debug'
import { PIXEL_RATIO } from '@/utils/constants'
import { roundRect } from '@/utils/canvasHelpers'

const log = debug('app:components/PDFPage')
export default {
  name: 'PdfPage',
  data() {
    return {
      mouseDownCoords: { x: 0, y: 0 },
      mouseMoveCoords: { x: 0, y: 0 },
      isMouseDown: false,
      isPageRendered: false,
    }
  },

  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    optimalScale: {
      type: Number,
      required: true,
    },
    isPageFocused: {
      type: Boolean,
      default: false,
    },
    isElementVisible: {
      type: Boolean,
      default: false,
    },
    isElementFocused: {
      type: Boolean,
      default: false,
    },
    fields: [Object, Array],
    pageSizes: Array,
    isSnipActivated: Boolean,
  },

  computed: {
    pageScalingFactor() {
      return this.canvasAttrs.height / this.originalPageSize.height
    },
    canvasRatio() {
      const { width } = this.$el
      const { width: canvasWidth } = this.canvasStyle
      return width / canvasWidth
    },

    actualSizeViewport() {
      return this.viewport.clone({ scale: this.scale })
    },

    canvasStyle() {
      const { width: actualSizeWidth, height: actualSizeHeight } =
        this.actualSizeViewport
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight].map(
        (dim) => Math.ceil(dim / PIXEL_RATIO)
      )
      const style = {
        width: pixelWidth,
        height: pixelHeight,
        string: `width: ${pixelWidth}px; height: ${pixelHeight}px;`,
      }
      return style
    },

    canvasAttrs() {
      let { width, height } = this.viewport
      ;[width, height] = [width, height].map((dim) => Math.ceil(dim))
      const style = this.canvasStyle.string

      return {
        width,
        height,
        style,
        class: 'pdf-page box-shadow',
      }
    },

    pageNumber() {
      return this.page.pageNumber
    },

    originalPageSize() {
      return (
        this.pageSizes[this.pageNumber - 1] || { width: 2480, height: 3508 } // fall back to default DinA4 sizes
      )
    },

    fieldsOnPage() {
      return Object.entries(this.fields)
        .filter(([key, el]) => {
          const hasArea = el.width * el.height > 0
          const isCorrectPage = this.pageNumber === el.page
          return hasArea && isCorrectPage
        })
        .map(([key, el]) => el)
    },
  },

  methods: {
    mouseLeave() {
      document.body.style.cursor = 'default'
    },
    hoverCheck(event) {
      const scaler = (value) => {
        return value / this.pageScalingFactor
      }
      const { layerX, layerY } = event
      const [x, y] = [
        scaler(layerX * this.canvasRatio),
        scaler(layerY * this.canvasRatio),
      ]
      const target = this.fieldsOnPage.find((field) => {
        const offset = 5
        const cursorX =
          x >= field.x - offset && x <= field.x + field.width + offset
        const cursorY =
          y >= field.y - offset && y <= field.y + field.height + offset
        return cursorX && cursorY
      })

      document.body.style.cursor = target ? 'pointer' : 'default'

      return target
    },

    mouseDown(event) {
      const { layerX, layerY } = event
      this.mouseDownCoords = { x: layerX, y: layerY }
      this.mouseMoveCoords = { x: layerX, y: layerY }
      this.isMouseDown = true
    },
    mouseUp(event) {
      const scaler = (value) => {
        return Number(
          ((this.canvasRatio * value) / this.pageScalingFactor).toFixed(0)
        )
      }

      if (this.isMouseDown) {
        const { x: startX, y: startY } = this.mouseDownCoords
        const { x: endX, y: endY } = this.mouseMoveCoords
        if (this.isSnipActivated) {
          this.renderSelectedArea(this.mouseDownCoords, this.mouseMoveCoords)
          const boundingBox = {
            x: scaler(startX),
            y: scaler(startY),
            width: scaler(endX - startX),
            height: scaler(endY - startY),
            pageNum: this.pageNumber,
          }
          this.$emit('snipAreaSelected', boundingBox)
        } else {
          const target = this.hoverCheck(event)
          if (target) {
            navigator.clipboard
              .writeText(target.value)
              .then(() => {
                this.$root.setSnackbar(
                  'success',
                  `${target.value} wurde kopiert`
                )
              })
              .catch((err) => {
                this.$root.setSnackbar('error', err)
              })
          }
        }
        this.mouseDownCoords = { x: 0, y: 0 }
        this.mouseMoveCoords = { x: 0, y: 0 }
        this.isMouseDown = false
      }
    },
    mouseMove(event) {
      if (!this.isSnipActivated) this.hoverCheck(event)
      const { layerX, layerY } = event
      this.mouseMoveCoords = { x: layerX, y: layerY }
      if (this.isSnipActivated && this.isMouseDown) {
        this.renderSelectedArea(this.mouseDownCoords, this.mouseMoveCoords)
      }
    },

    renderSelectedArea(start, end) {
      const [xA, yA] = [start.x * this.canvasRatio, start.y * this.canvasRatio]
      const [xB, yB] = [end.x * this.canvasRatio, end.y * this.canvasRatio]

      const fieldCoords = {
        startX: Math.min(xA, xB),
        startY: Math.min(yA, yB),
        endX: Math.max(xA, xB),
        endY: Math.max(yA, yB),
      }
      if (!this.isPageRendered) {
        this.destroyRenderTask()
        this.drawPage(fieldCoords)
      }
    },

    focusPage() {
      if (this.isPageFocused) return

      this.$emit('page-focus', this.pageNumber)
    },

    drawPage: function (fieldCoords) {
      if (this.renderTask) return

      const { viewport } = this
      const canvasContext = this.$el.getContext('2d')
      const renderContext = { canvasContext, viewport }

      // PDFPageProxy#render
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      this.isPageRendered = true
      this.renderTask = this.page.render(renderContext)
      this.renderTask
        .then(() => {
          this.$emit('page-rendered', {
            page: this.page,
            text: `Rendered page ${this.pageNumber}`,
          })
        })
        .then(() => {
          const scaler = (value) => {
            return value * this.pageScalingFactor
          }

          this.fieldsOnPage.forEach((field) => {
            const color = 'rgba(255, 0, 0, .3)'
            canvasContext.fillStyle = color
            canvasContext.strokeStyle = color
            const offset = 5
            roundRect(
              canvasContext,
              scaler(field.x - offset),
              scaler(field.y - offset),
              scaler(field.width + 2 * offset),
              scaler(field.height + 2 * offset)
            )
          })

          if (this.isSnipActivated) {
            canvasContext.fillStyle = 'rgba(255, 255, 255, 0.7)'
            canvasContext.strokeStyle = 'rgba(0, 0, 0, 1)'

            canvasContext.beginPath()
            canvasContext.moveTo(0, 0)
            canvasContext.lineTo(this.$el.width, 0)
            canvasContext.lineTo(this.$el.width, this.$el.height)
            canvasContext.lineTo(fieldCoords.endX, this.$el.height)
            canvasContext.lineTo(fieldCoords.endX, fieldCoords.startY)
            canvasContext.lineTo(fieldCoords.startX, fieldCoords.startY)
            canvasContext.lineTo(fieldCoords.startX, fieldCoords.endY)
            canvasContext.lineTo(fieldCoords.endX, fieldCoords.endY)
            canvasContext.lineTo(fieldCoords.endX, this.$el.height)
            canvasContext.lineTo(0, this.$el.height)
            canvasContext.closePath()
            canvasContext.fill()

            canvasContext.beginPath()
            canvasContext.rect(
              fieldCoords.startX,
              fieldCoords.startY,
              fieldCoords.endX - fieldCoords.startX,
              fieldCoords.endY - fieldCoords.startY
            )
            canvasContext.stroke()
          }
          this.isPageRendered = false
        })
        .catch((response) => {
          this.destroyRenderTask()
          this.$emit('page-errored', {
            response,
            page: this.page,
            text: `Failed to render page ${this.pageNumber}`,
          })
          this.isPageRendered = false
        })
    },

    updateVisibility() {
      this.$parent.$emit('update-visibility')
    },

    destroyPage(page) {
      // PDFPageProxy#_destroy
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      if (page) {
        page._destroy()
      }

      this.destroyRenderTask()
    },

    destroyRenderTask() {
      if (!this.renderTask) {
        return
      }

      // RenderTask#cancel
      // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
      this.renderTask.cancel()
      delete this.renderTask
    },
  },

  watch: {
    scale: 'updateVisibility',

    page(_newPage, oldPage) {
      this.destroyPage(oldPage)
    },

    isElementFocused(isElementFocused) {
      if (isElementFocused) {
        this.focusPage()
      }
    },

    isElementVisible(isElementVisible) {
      if (isElementVisible) {
        this.drawPage()
      }
    },
    isSnipActivated(value) {
      this.mouseDownCoords = { x: 0, y: 0 }
      this.mouseMoveCoords = { x: 0, y: 0 }

      const fieldCoords = { startX: 0, startY: 0, endX: 0, endY: 0 }
      this.isMouseDown = false
      if (!this.isPageRendered) {
        this.destroyRenderTask()
        this.drawPage(fieldCoords)
      }
    },
  },

  created() {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    this.viewport = this.page.getViewport(this.optimalScale)
  },

  mounted() {
    this.$el.addEventListener('mousedown', this.mouseDown)
    this.$el.addEventListener('mouseup', this.mouseUp)
    this.$el.addEventListener('mousemove', this.mouseMove)
    this.$el.addEventListener('click', this.click)
    this.$el.addEventListener('mouseleave', this.mouseLeave)

    log(`Page ${this.pageNumber} mounted`)
  },

  beforeDestroy() {
    this.destroyPage(this.page)
  },

  render(h) {
    const { canvasAttrs: attrs } = this
    return h('canvas', { attrs })
  },
}
</script>
<style>
.pdf-page {
  display: block;
  margin: 0 auto;
}
</style>

<template>
  <v-flex class="pdf-view fill-height" v-bind="{ xs6 }">
    <div class="pdf-viewer">
      <header>
        <PDFZoom
          :scale="scale"
          @change="updateScale"
          @fit="updateFit"
          @download="downloadPDF"
          :is-snip-activated="isSnipActivated"
          @snipModeChange="$emit('snipModeChange')"
          class="header-item"
        />
      </header>
      <PDFData
        v-bind="{ currentPdf }"
        @page-count="updatePageCount"
        @page-focus="updateCurrentPage"
        @document-rendered="onDocumentRendered"
        @document-errored="onDocumentErrored"
      >
        <PDFDocument
          :is-snip-activated="isSnipActivated"
          @opt-scale-change="updateOptScale"
          @scale-change="updateScale"
          @snipAreaSelected="(val) => $emit('snipAreaSelected', val)"
          class="pdf-viewer__document"
          slot-scope="{ pages }"
          v-bind="{
            pages,
            scale,
            optimalScale,
            fit,
            currentPage,
            pageCount,
            extractionData,
          }"
        />
      </PDFData>
    </div>
  </v-flex>
</template>

<script>
import PDFDocument from './PDFDocument'
import PDFData from './PDFData'
import PDFZoom from './PDFZoom'
import documentsApi from '@/api/documentsApi'

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.floor(value * multiplier) / multiplier
}

export default {
  name: 'PdfViewer',
  components: {
    PDFDocument,
    PDFData,
    PDFZoom,
  },
  props: {
    extractionData: {
      type: Object,
      required: true,
    },
    xs6: {
      type: Boolean,
      default: true,
    },
    isSnipActivated: Boolean,
  },
  data() {
    return {
      scale: undefined,
      optimalScale: undefined,
      fit: undefined,
      currentPage: 1,
      pageCount: undefined,
    }
  },

  computed: {
    currentPdf() {
      return this.extractionData.pdf
    },
  },

  methods: {
    updateOptScale(newScale) {
      this.optimalScale = newScale
    },
    onDocumentRendered() {
      this.$emit('document-rendered', this.currentPdf)
    },

    onDocumentErrored(e) {
      this.$emit('document-errored', e)
    },

    updateScale({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2)
      if (isOptimal) this.optimalScale = roundedScale
      this.scale = roundedScale
    },

    updateFit(fit) {
      this.fit = fit
    },

    updatePageCount(pageCount) {
      this.pageCount = pageCount
    },

    updateCurrentPage(pageNumber) {
      this.currentPage = pageNumber
    },
    async downloadPDF() {
      await documentsApi.downloadPDF(this.extractionData.pdf)
    },
  },
}
</script>

<style lang="scss" scoped>
$pdfSize: 50%;
$dataViewSize: 50%;

.pdf-view {
  display: flex;
}

.pdf-viewer {
  width: 100%;
  padding: 5px;
  position: relative;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 24px;
}

.header-item {
  margin: 0 10px;
}

.pdf-viewer__document {
  width: 100%;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',_vm._b({staticClass:"pdf-view fill-height"},'v-flex',{ xs6: _vm.xs6 },false),[_c('div',{staticClass:"pdf-viewer"},[_c('header',[_c('PDFZoom',{staticClass:"header-item",attrs:{"scale":_vm.scale,"is-snip-activated":_vm.isSnipActivated},on:{"change":_vm.updateScale,"fit":_vm.updateFit,"download":_vm.downloadPDF,"snipModeChange":function($event){return _vm.$emit('snipModeChange')}}})],1),_c('PDFData',_vm._b({on:{"page-count":_vm.updatePageCount,"page-focus":_vm.updateCurrentPage,"document-rendered":_vm.onDocumentRendered,"document-errored":_vm.onDocumentErrored},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pages = ref.pages;
return _c('PDFDocument',_vm._b({staticClass:"pdf-viewer__document",attrs:{"is-snip-activated":_vm.isSnipActivated},on:{"opt-scale-change":_vm.updateOptScale,"scale-change":_vm.updateScale,"snipAreaSelected":function (val) { return _vm.$emit('snipAreaSelected', val); }}},'PDFDocument',{
          pages: pages,
          scale: _vm.scale,
          optimalScale: _vm.optimalScale,
          fit: _vm.fit,
          currentPage: _vm.currentPage,
          pageCount: _vm.pageCount,
          extractionData: _vm.extractionData,
        },false))}}])},'PDFData',{ currentPdf: _vm.currentPdf },false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
export function roundRect(
  ctx,
  x,
  y,
  width,
  height,
  radius = 5,
  fill = true,
  stroke = true
) {
  const radiusObj = { tl: radius, tr: radius, br: radius, bl: radius }
  ctx.beginPath()
  ctx.moveTo(x + radiusObj.tl, y)
  ctx.lineTo(x + width - radiusObj.tr, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radiusObj.tr)
  ctx.lineTo(x + width, y + height - radiusObj.br)
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radiusObj.br,
    y + height
  )
  ctx.lineTo(x + radiusObj.bl, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radiusObj.bl)
  ctx.lineTo(x, y + radiusObj.tl)
  ctx.quadraticCurveTo(x, y, x + radiusObj.tl, y)
  ctx.closePath()
  if (fill) {
    ctx.fill()
  }
  if (stroke) {
    ctx.stroke()
  }
}
